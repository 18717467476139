.main-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header {
  margin-bottom: 20px;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
}

section {
  margin-bottom: 30px;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}
