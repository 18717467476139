/* Reset some default styles */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

/* Apply some basic styles to the App component */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
}

/* Customize the header styles */
.App-header {
  background-color: #333;
  padding: 20px;
  color: #fff;
}

.App-header h1 {
  font-size: 24px;
  font-weight: bold;
}

/* Add more styles as needed */
