.under-construction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f0f0f0;
}

.under-construction img {
  width: 300px; /* Adjust the width as needed */
  height: auto;
  margin-bottom: 20px;
}

.under-construction h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.under-construction p {
  font-size: 18px;
}

.construction-image {
  width: 100%;
  max-width: 800px; /* Adjust the maximum width as needed */
  height: auto;
}
